import { Link } from "react-router-dom";

import { Tooltip } from "react-tooltip";

const Home = () => {
  const start = () => {};

  return (
    <div className="shell">
      <h2>Infrafon Suite</h2>
      <div className={"grid"}>
        <a href={"https://app.element.io/#/login"} className={"appContainer"}data-tooltip-id="message-tooltip">
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"email.png"}
              alt={"message icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Messages</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>matrix</span>
        </a>
        <Link to="/nfc" className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"nfc.png"}
              alt={"nfc icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>NFC-Emulation</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>security patterns</span>
        </Link>
        <Link to="/scan" className={"appContainer"}>
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"nfc.png"}
              alt={"nfc icon"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>NFC-Scan</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>security patterns</span>
        </Link>
        <a
          href={"https://cedalo.com/pro-streamsheets/"}
          className={"appContainer"}
        >
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"streamsheets.png"}
              alt={"streamsheets"}
              height={30}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Streamsheets</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>cedalo</span>
        </a>
        <a href={"https://demo-orion.syookinsite.com/entity"} className={"appContainer"} data-tooltip-id="syook-tooltip">
          <div className={"flex-row"} style={{ marginBottom: "0.5rem" }}>
            <img
              src={"tool.png"}
              height={30}
              alt={"syook localization"}
              style={{ marginRight: "0.5rem" }}
            />
            <span className={"formHeading"}>Localization</span>
          </div>
          <span className={"text"}>powered by</span>
          <span className={"text font-weight-bold"}>syook</span>
        </a>
      </div>
      <Tooltip id="syook-tooltip" clickable>
        <div style={{ padding: "8px", textAlign: "center" }}>
          <div>For a detailed tutorial, click the link below:</div>
          {/* Add a styled and clickable link */}
          <a
            href="https://drive.google.com/file/d/1dUYAq5I85UxA4xKC7z1S2j7MojfekSc5/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: "#007bff",
              textDecoration: "underline",
              fontWeight: "bold",
            }}
          >
            Watch Tutorial
          </a>
        </div>
      </Tooltip>
      <Tooltip id="message-tooltip">
  Please change the home server to: 
  <div style={{ marginTop: "5px", fontWeight: "bold", textAlign: "center"}}>
    <span style={{ textDecoration: "underline" }}>matrix.infrafon.club</span>
  </div>
</Tooltip>

    </div>
  );
};

export default Home;
