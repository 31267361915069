import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import mqtt from "mqtt";
import { useWizard } from "react-use-wizard";
import "./NFCscan.css"; // Ensure your CSS file is linked
import QRCodeScanner from "../components/QRCodeScanner";
import { FaQrcode } from "react-icons/fa";
import AllocationWizardData from "./AllocationWizardData";
import AllocationWizardAllocate from "./AllocationWizardAllocate";
import { Tooltip } from "react-tooltip";
import Joyride from "react-joyride";

const axios = require("axios");

// Function to check device state
function NFCscan({ newDeviceId }) {
  const { previousStep } = useWizard();
  const [socket, setSocket] = useState(null);
  const [id, setId] = useState(""); // State to hold the ID
  const [showPopup, setShowPopup] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [logs, setLogs] = useState([]);
  const [showLogs, setShowLogs] = useState(false);
  const [currentLogIndex, setCurrentLogIndex] = useState(0); // Current start index of the log
  const [ID, setID] = useState(""); // New ID state
  const [error, setError] = useState(false); // Error state variableslice
  const pageSize = 7; // Number of logs to display at a time
  const [isHovered, setIsHovered] = useState(false); // State for hover behavior
  const [progress, setProgress] = useState(0); // State for progress bar
  const [isScanning, setIsScanning] = useState(false); // State for scanning
  const [scanStatus, setScanStatus] = useState(""); // State for scan status (success/error)

  const [startMainTour, setStartMainTour] = useState(true); // Control main Joyride
  const [startPopupTour, setStartPopupTour] = useState(false); // Control popup Joyride

  // Run main Joyride for 30 seconds after render
  useEffect(() => {
    const mainTourTimeout = setTimeout(() => setStartMainTour(false), 60000); // 60 seconds
    return () => clearTimeout(mainTourTimeout);
  }, []);

  // Run popup Joyride for 30 seconds after the popup is rendered
  useEffect(() => {
    if (showPopup) {
      setStartMainTour(false)
      setTimeout(() => setStartPopupTour(true), 200); // Short delay to ensure popup renders
      const popupTourTimeout = setTimeout(() => setStartPopupTour(false), 60000); // 60 seconds
      return () => clearTimeout(popupTourTimeout);
    }
  }, [showPopup]);
  const steps = [
    {
      target: ".input-group", // Ensure this class is visible
      content: "This is where you enter the patient's name.",
    },
    {
      target: ".button-primary.Add", // Ensure this class is visible
      content: "Use Add button to add new patient information.",
    },
    {
      target: ".button-danger.Delete", // Ensure this class is visible
      content: "Use Delete button to delete patient information.",
    },
  ];
  const steps2 = [
    {
      target: ".form-control.name-input",
      content: "Enter the full name of the patient here.",
    },
    {
      target: ".form-control.medication-interval-input",
      content:
        "Specify how often the patient should be reminded to take medication (in hours).",
    },
    {
      target: ".form-control.reminder-interval-input",
      content: "Specify a reminder interval for any other alerts.",
    },
    {
      target: ".scan-button",
      content:
        "Press the scan button to activate the device and place device on NFC reader to get the device-id automatically.",
    },
    {
      target: ".form-control.id-input",
      content: "Enter device-id manually if needed.",
    },
    {
      target: ".form-control.zimmer-input",
      content: "Enter the room number of the patient.",
    },
    {
      target: ".form-control.bett-input",
      content: "Enter the bed number of the patient.",
    },
    {
      target: ".reg-submit",
      content: "Finally press Submit to register the patient information.",
    },
  ];
  const initialNewUserState = {
    name: "",
    medicationInterval: "",
    reminderInterval: "",
    id: ID,
    zimmer: "",
    bett: "",
  };
  const [newUser, setNewUser] = useState(initialNewUserState);
  const initialDeleteUserState = {
    deletename: "",
  };
  const [deleteuser, setDeleteUser] = useState(initialDeleteUserState);

  useEffect(() => {
    const newSocket = new WebSocket("wss://nodered.infrafon.club/te");
    newSocket.onopen = () => {
      console.log("Connected to WebSocket securely");
      // Send a message as soon as the connection is opened
      newSocket.send(
        JSON.stringify({ payload: "device", topicid: newDeviceId })
      );
    };
    newSocket.onmessage = (event) => {
      console.log("Message from server: ", event.data);

      let data;
      try {
        data = JSON.parse(event.data); // Try to parse the event data as JSON
      } catch (error) {
        console.log("Received non-JSON message:", event.data);
        data = event.data; // Treat it as a plain string if parsing fails
      }
      if (typeof data === "string") {
        // If it's an NFC scan result (like "B9C58B3"), update the ID field
        setNewUser((prevUser) => ({ ...prevUser, id: data }));
      }
      if (Array.isArray(data)) {
        // Check if the incoming data is an array
        setLogs(data); // Update the logs state with the incoming log data
      } else {
        console.log("Received non-array data:", data);
      }
      if (data.success === true) {
        alert(data.message); // Display an alert if there is an issue (e.g., duplicate)
        toast.success("New Person Successfully Added", { autoClose: 2000 });
      } else if (data.success === false) {
        toast.error(`${data.message}`, { autoClose: 2000 });
      }
    };
    newSocket.onerror = (error) => {
      console.log("WebSocket error: ", error);
      toast.error("WebSocket error");
    };
    setSocket(newSocket);

    return () => newSocket.close();
  }, []);

  useEffect(() => {
    // Simulate scanning process or integrate real scan logic here
    if (newDeviceId) {
      const message = JSON.stringify({ deviceId: newDeviceId });
      console.log("Sending deviceId via WebSocket:", message);
      //socket.send(message);
    }
  }, [newDeviceId, socket]);

  const sendMessage = (payload, id) => {
    const message = JSON.stringify({ payload, id });
    console.log("Sending message:", message); // Log the message to be sent
    if (socket) {
      socket.send(message);
    }
  };
  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };
  const handleInputChange2 = (e) => {
    setDeleteUser({ ...deleteuser, [e.target.name]: e.target.value });
  };

  const submitNewUser = () => {
    if (socket) {
      socket.send(JSON.stringify(newUser));
      setNewUser(initialNewUserState); // Reset the newUser state to clear the form

      setShowPopup(false); // Close the popup after sending
    }
  };
  const DeleteUser = () => {
    if (socket) {
      socket.send(JSON.stringify(deleteuser));
      setNewUser(initialDeleteUserState); // Reset the newUser state to clear the form
      setShowDeletePopup(false); // Close the popup after sending
      toast.success("delete successfully  ", { autoClose: 2000 });
    }
  };

  const handleClosePopup = () => {
    setNewUser(initialNewUserState); // Reset the newUser state to clear the form
    setShowPopup(false); // Close the popup
    setNewUser(initialDeleteUserState); // Reset the newUser state to clear the form
    setShowDeletePopup(false); // Close the popup after sending
    setLogs([]);
    setShowLogs(false);
  };
  const handleNext = () => {
    // Move to next set of logs if not at the end
    if (currentLogIndex + pageSize < logs.length) {
      setCurrentLogIndex(currentLogIndex + pageSize);
    }
  };

  const handlePrevious = () => {
    // Move to previous set of logs if not at the start
    if (currentLogIndex > 0) {
      setCurrentLogIndex(currentLogIndex - pageSize);
    }
  };
  const toggleLogs = () => {
    setShowLogs((prev) => !prev); // Toggle the visibility of logs
    if (!showLogs) {
      // If logs are not currently shown, request them
      socket.send(JSON.stringify("loog"));
    }
  };

  const handleScan = () => {
    setIsHovered(false); // Reset hover state
    setProgress(0); // Reset progress to 0
    setScanStatus(""); // Reset scan status
    setIsScanning(true); // Indicate scanning

    let interval; // To track progress interval

    // Simulate a scan timeout for 30 seconds with progress
    const scanTimeout = setTimeout(() => {
      if (!newUser.id) {
        // If no ID is received within 30 seconds, mark scan as error
        setScanStatus("error");
        toast.error("SCAN AGAIN");
        setIsScanning(false);
      }
      clearInterval(interval); // Clear the progress interval when timeout is reached
    }, 30000); // 30 seconds

    // Increment the progress bar over time (increase width every 300ms)
    interval = setInterval(() => {
      setProgress((prev) => {
        const newProgress = prev + 1;
        if (newProgress >= 100) {
          clearInterval(interval); // Stop when progress reaches 100%
        }
        return newProgress;
      });
    }, 300); // Update progress every 300ms (approx. 1% every 300ms)

    // Initiate the WebSocket scan action

    socket.send(JSON.stringify("scan"));

    // Reset scan button based on WebSocket response
    socket.onmessage = (event) => {
      clearTimeout(scanTimeout); // Clear the timeout when a response is received
      clearInterval(interval); // Stop progress bar

      let data;
      try {
        data = JSON.parse(event.data);
      } catch (error) {
        console.log("Received non-JSON message:", event.data);
        data = event.data; // Treat it as a plain string if parsing fails
      }

      if (typeof data === "string") {
        // If an NFC ID (like "B9C58B3") is received, update the ID input and reset the button
        setNewUser((prevUser) => ({ ...prevUser, id: data }));
        setScanStatus("success");
        toast.success("Scanned successfuly", { autoClose: 1500 });
        setProgress(100); // Complete the progress bar
        setIsScanning(false); // Reset scanning state
        setTimeout(() => {
          setScanStatus(""); // Reset to default (blue)
        }, 10000);
      }
    };

    socket.onerror = (error) => {
      console.log("WebSocket error: ", error);
      setScanStatus("error"); // Set button to error state if WebSocket fails
      setIsScanning(false);
      clearInterval(interval); // Stop progress bar
    };
  };

  return (
    <div className="new">
      <Joyride
        steps={steps}
        continuous={true}
        scrollToFirstStep={true}
        showProgress={true}
        showSkipButton={true}
        run={startMainTour} // Ensure tour runs automatically
        debug={true} // Enable debugging to see console logs
      />
      <div className="input-group">
        <input
          type="text"
          value={id}
          onChange={(e) => setId(e.target.value)}
          placeholder="Enter Patient name "
        />
        <button onClick={() => sendMessage("start", id)}>Start Process</button>
      </div>

      <div className="button-group">
        <button
          className="button-primary Add"
          onClick={() => setShowPopup(true)}
        >
          Add New User
        </button>
        <button
          className="button-danger Delete"
          onClick={() => setShowDeletePopup(true)}
        >
          Delete User
        </button>
        <button className="button-primary" onClick={toggleLogs}>
          {showLogs ? "Hide Logs" : "Show Logs"}
        </button>
      </div>

      {showLogs && (
        <div
          style={{
            position: "absolute",
            top: "40%",
            left: "30%",
            backgroundColor: "lightgray",
            padding: 20,
            zIndex: 100,
          }}
        >
          <h2>Log Entries</h2>
          <ul>
            {logs
              .slice(currentLogIndex, currentLogIndex + pageSize)
              .map((log, index) => (
                <li key={index + currentLogIndex}>{log}</li>
              ))}
          </ul>
          <button onClick={handlePrevious} disabled={currentLogIndex === 0}>
            Previous
          </button>
          <button
            onClick={handleNext}
            disabled={currentLogIndex + pageSize >= logs.length}
          >
            Next
          </button>
          <button onClick={handleClosePopup}>Close Logs</button>
        </div>
      )}

      {showPopup && (
        <div className="popup">
          {startPopupTour && (
            <Joyride
              steps={steps2}
              continuous={true}
              scrollToFirstStep={true}
              showProgress={true}
              showSkipButton={true}
              run={startPopupTour}
              debug={true}
            />
          )}
          <div className="form-control name-input">
            <label className="label" data-tooltip-id="name-tooltip">Name</label>
            <input
              className="input"
              type="text"
              name="name"
              placeholder="Name"
              onChange={handleInputChange}
              value={newUser.name}
              
            />
          </div>
          
          <div className="form-control medication-interval-input">
            <label
              className="label"
              data-tooltip-id="medication-tooltip"
            >
              Medication Interval
            </label>
            <input
              className="input"
              type="number"
              name="medicationInterval"
              placeholder="Medication Interval"
              onChange={handleInputChange}
              value={newUser.medicationInterval}
            />
          </div>
         
          <div className="form-control reminder-interval-input">
            <label className="label" data-tooltip-id="reminder-tooltip">Reminder Interval</label>
            <input
              className="input"
              type="number"
              name="reminderInterval"
              placeholder="Reminder Interval"
              onChange={handleInputChange}
              value={newUser.reminderInterval}
            />
          </div>
          <div className="form-control id-input">
            <label className="label" data-tooltip-id="id-tooltip">ID</label>
            <input
              className={`input ${error ? "input-error" : ""}`} // Add 'input-error' class if there's an error
              type="text"
              name="id"
              placeholder="ID"
              onChange={handleInputChange}
              value={newUser.id || ""} // Use newUser.id if it's set, otherwise fallback to ID
            />
            <button
              className={`scan-button ${
                scanStatus === "error"
                  ? "scan-button-error"
                  : scanStatus === "success"
                  ? "scan-button-success"
                  : ""
              }`}
              onClick={handleScan}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              data-tooltip-id="scan-tooltip"
            >
              <div
                className="scan-progress"
                style={{ width: `${progress}%` }}
              ></div>
              <span className="scan-button-text">Scan</span>
            </button>
          </div>
          <div className="form-control zimmer-input">
            <label className="label" data-tooltip-id="zimmer-tooltip">Zimmer</label>
            <input
              className="input"
              type="text"
              name="zimmer"
              placeholder="Zimmer"
              onChange={handleInputChange}
              value={newUser.zimmer}
            />
          </div>
          <div className="form-control bett-input">
            <label className="label" data-tooltip-id="bett-tooltip">Bett</label>
            <input
              className="input"
              type="number"
              name="bett"
              placeholder="Bett"
              onChange={handleInputChange}
              value={newUser.bett}
            />
          </div>
          <button class="reg-submit" onClick={submitNewUser} data-tooltip-id="submit-tooltip">Submit</button>

          <button onClick={handleClosePopup} data-tooltip-id="cancel-tooltip">Cancel</button>
          <Tooltip id="name-tooltip" place="top">
            Name of the patient.
          </Tooltip>
          <Tooltip id="medication-tooltip" place="top">
            Interval (in hours) between medication reminders.
          </Tooltip>
          <Tooltip id="reminder-tooltip" place="top">
            Interval (in hours) between reminders.
          </Tooltip>
          <Tooltip id="id-tooltip" place="top">
            Device-Id for patient.
          </Tooltip>
          <Tooltip id="scan-tooltip" place="top">
            Press the button and place device on NFC reader to retrieve device-id.
          </Tooltip>
          <Tooltip id="zimmer-tooltip" place="top">
            Room number of patient.
          </Tooltip>
          <Tooltip id="bett-tooltip" place="top">
            Bett number of patient.
          </Tooltip>
          <Tooltip id="submit-tooltip" place="top">
            Press to register the patient information.
          </Tooltip>
          <Tooltip id="cancel-tooltip" place="top">
            Cancel registration process.
          </Tooltip>
        </div>
      )}
      {showDeletePopup && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)", // Center the popup horizontally and vertically

            backgroundColor: "white",
            padding: 20,
            zIndex: 100,
            width: "20%", // Optional: Adjust to match your desired size
            maxWidth: "600px", // Ensure it doesn't go too large
            boxShadow: "0 4px 12px rgba(0, 0, 0, 0.15)", // For better visual appeal
            borderRadius: "10px", // Optional: for rounded corners
          }}
        >
          <input
            type="text"
            name="deletename"
            placeholder="Name"
            onChange={handleInputChange2}
            value={deleteuser.deletename}
          />
          <button onClick={DeleteUser}>Delete</button>

          <button onClick={handleClosePopup}>Cancel</button>
        </div>
      )}
      <ToastContainer />
    </div>
  );
  // State to handle connection status
}

export default NFCscan;
