import React, {useState, useEffect} from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
//import { useWizard } from 'react-use-wizard';
import mqtt from 'mqtt';
import './NFCui.css'; // Ensure your CSS file is linked

const axios = require('axios');



// Function to check device state
function NFCuiReturn() {
    // State to handle connection status
    const [isConnected, setIsConnected] = useState(false);
    const [newDeviceId, setNewDeviceId] = useState('');
    const navigate = useNavigate();
    const [mqttClient, setMqttClient] = useState(null);
    const [statusMessage, setStatusMessage] = useState('Connecting to MQTT broker...');
    const [Location, setLocation] = useState('Security');
    const [shouldReconnect, setShouldReconnect] = useState(true); // Define shouldReconnect here
    const [receivedMessage, setReceivedMessage] = useState('');
    const [parsedMessage, setParsedMessage] = useState(null); // Declare parsedMessage state
    const [parseError, setParseError] = useState('');
    const [messageTopic, setMessageTopic] = useState(''); // State variable for the topic
    const [deviceId, setDeviceId] = useState('');
    const [devices, setDevices] = useState([]); // State to store the list of devices
    const [workers, setWorkers] = useState({});
    const [showTasksAndMessagesPopup, setShowTasksAndMessagesPopup] = useState(false);
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [confirmResult, setConfirmResult] = useState(null);
    const [showAddDevicePopup, setShowAddDevicePopup] = useState(false);
    //const [newDeviceId, setNewDeviceId] = useState('');
    const [newDeviceName, setNewDeviceName] = useState('');
    const [currentUser, setCurrentUser] = useState(null); // or some initial value
    const [tableData, setTableData] = useState([]);
    const [lastErrorMessage, setLastErrorMessage] = useState('');
    const [logMessages, setLogMessages] = useState([]);
    const [showScanner, setShowScanner] = useState(false);
    const [isDeviceAdded, setIsDeviceAdded] = useState(false); // Track if a device is currently added
    const [isDeviceIdsReady, setIsDeviceIdsReady] = useState(false);
    const [VisitorId, setVisitorId] = useState('');

    const [returnPopupData, setReturnPopupData] = useState({
        deviceId: '',
        deviceName: '',
        workerName: '',
        comments: ''
    });
    const [showReturnPopup, setShowReturnPopup] = useState(false);
    //////////////////////////////new.v1///////////////////////
    /**
     * Function to open the return device popup
     */
    let globalVisitorId = null;
    async function checkDeviceState(deviceId) {
        updateLog("check")
        const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        };
        let res = undefined
        try {
            res = await fetch(`https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device/${deviceId}`, requestOptions)
            const data = await res.json();
            updateLog(`Received data: ${JSON.stringify(data)}`);
            const visitorId = data.data.allocation.device_data.user.visitor;
            updateLog("visitor"+visitorId)
            if (data.data.state.current === "DEP_DEALLOCING") {
                popup("Device is in DEP_DEALLOCING state.");
                return false;
                // Handle the case where device is in DEP_DEALLOCING state
            }
            else  if (data.data.state.current === 'DEP_ALLOCED' || data.data.state.current === 'DEP_ALLOCING') {
                updateLog("Device is in DEP_ALLOCED state.");
                if ( globalVisitorId === null) {
                    globalVisitorId = visitorId;
                    setVisitorId(globalVisitorId);
                    setLocation('Greenhouse1')
                } else {
                    updateLog("No visitor ID present."+ VisitorId);
                }
                return true;
                // Handle the case where device is in DEP_DEALLOCING state
            }
            else {
                if (res.status === 404) {
                    popup('Infrafon device is not registered!')
                } else {
                    if (res.status >= 400) {
                        popup('Server communication error')
                    }
                }
            }

            // Handle unexpected device state

            if (!res.ok) {
                console.log('Server communication error')
                return false
            }
            if (res.status >= 400) {
                console.log('Server communication error')
                return false
            }
        }
        catch (e) {
            console.log('Server communication error')
            return false
        }

    }
//-----------------------------------------------------------------------------
    const [deviceIds, setDeviceIds] = useState([]); // Store fetched device IDs here
    /**
     Fetching Device IDs:
     The component fetches device IDs from an external API endpoint using the fetchDeviceIds function when the component mounts.
     It updates the state with the fetched device IDs and sets a flag (isDeviceIdsReady) to indicate that the device IDs are available.
     */
    // Fetch device IDs when the component mounts
    useEffect(() => {
        async function fetchDeviceIds() {
            const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
            const requestOptions = {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                }
            };

            try {
                const response = await fetch(`https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device`, requestOptions);
                const data = await response.json();
                if(data && data.data) {
                    // Assuming 'data.data' contains your devices array
                    const ids = data.data.map(device => device.name);

                    setDeviceIds(ids);
                    setIsDeviceIdsReady(true);



                }
            } catch (error) {
                updateLog('Error fetching device IDs:', error);
            }
        }
        fetchDeviceIds();
    }, []);
    useEffect(() => {
        console.log("deviceIds has been updated", deviceIds);

    }, [deviceIds]);
    useEffect(() => {
        // Function to execute after setting tableData
        const updateTableImmediately = () => {
            // Perform any actions that need to be done immediately after setting tableData
            console.log('Table data has been updated:', tableData);
            // You can trigger any immediate actions here
        };

        // Call the function immediately after setting tableData
        updateTableImmediately();
    }, [tableData]);
///////////////////////////////new.v1//////////////////////////
    // ####################################################################

    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  pop up and handels msg//    *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    // This should be defined at the global scope of your code.



    const ConfirmModal = ({message, onConfirm, onCancel}) => (
        <div className="modal">
            <p>{message}</p>
            <button onClick={() => onConfirm(true)}>Yes</button>
            <button onClick={() => onConfirm(false)}>No</button>
        </div>
    );

    const popup = (msg) => {
        alert(msg); // Temporary for debugging

        setModalMessage(msg);
        setShowMessageModal(true);
        setLastErrorMessage(msg);
        // Improved log messages
        updateLog("Popup: Modal message set to: " + msg);
        updateLog("Popup: Show message modal set to true");
        updateLog("Popup: Last error message set to: " + msg);


    };

    const yesno = (msg) => {
        setModalMessage(msg);
        setShowConfirmModal(true);
    };

    const handleConfirmClose = (result) => {
        setConfirmResult(result);
        setShowConfirmModal(false);
        // Process the result as needed
    };



//########################################################################

///                 mqtt connection                                 ////

    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  mqtt connection//           *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    // Function to attempt MQTT connection
    const onLoginAttempt = () => {
        const options = {
            connectTimeout: 10000, // milliseconds
            username: 'Abdulrahman_dev',
            password: 'Welcome0!',
            reconnectPeriod: shouldReconnect ? 1000 : 0,
            // Enable SSL/TLS
            protocol: 'wss',
            port: 8443

        };

        // Corrected URL format
        const client = mqtt.connect('wss://mqtt.infrafon.cloud:8443/mqtt', options);
        setMqttClient(client);



        client.on('connect', () => {
            setIsConnected(true);
            console.log('Connected to MQTT broker');
            setStatusMessage('Connected to MQTT broker.');
            for (var i = 0; i < devices.length; i++) {
                mqtt_subscribe(client, devices[i].deviceId);
                updateLog("find device: Found - " + JSON.stringify(devices[i]));

            }


            // Here you can also subscribe to topics
        });

        client.on('message', (topic, message) => {
            const messageString = message.toString();
            setMessageTopic(topic);


            setReceivedMessage(messageString);
            updateLog("recived message "+messageString)

            // Check if the message is not empty and looks like JSON
            if (messageString.trim() && messageString.startsWith('{') && messageString.endsWith('}')) {
                try {
                    const json = JSON.parse(messageString);
                    setParsedMessage(json);
                    setParseError('');
                } catch (error) {
                    setParsedMessage(null);
                    setParseError('Error   parsing JSON: ' + error.message);
                }
            } else {
                setParsedMessage(null);
                setParseError('Received message is not valid JSON');
            }
        });


        client.on('error', (err) => {
            setIsConnected(false);
            setStatusMessage('Connection error: ' + err.message);
            console.error('MQTT connection error:', err);
            client.end();
        });


        client.on('close', () => {
            setIsConnected(false);
            setStatusMessage('Connection closed.');
            updateLog('MQTT connection closed.');
        });
        client.on('reconnect', () => {
            console.log('MQTT Client trying to reconnect');
        });

        client.on('offline', () => {
            setIsConnected(false);
            setStatusMessage('MQTT client is offline.');
            updateLog('MQTT client is offline.');
        });

        // Handle clean up on component unmount
        return () => {
            client.end();
        };
    };

    const mqtt_subscribe = (client, deviceId) => {
        try {
            client.subscribe('ife-int/device/msgs-up/' + deviceId);
            console.log('mqtt subscribe for ' + deviceId);
            setStatusMessage('mqtt subscribe for ' + deviceId);
            client.subscribe(`ife-int/device/msgs-up/${deviceId}`, { qos: 1 }, (err) => {
                if (err) {
                    console.error(`Failed to subscribe to ${deviceId}:`, err);
                } else {
                    console.log(`Subscribed to ${deviceId} successfully.`);
                }
            });
        } catch (e) {
            console.log(e);
        }
    };
    useEffect(() => {
        return () => {
            // This cleanup function is called on component unmount
            mqttClient?.end();
            console.log('Disconnected MQTT on component unmount');
        };
    }, []);
    useEffect(() => {
        if (!isDeviceIdsReady) return;
        onLoginAttempt();

        // Clean up function to run when component unmounts
        return () => {
            setShouldReconnect(false); // Ensure we don't try to reconnect after unmounting
        };
    }, [isDeviceIdsReady, deviceIds]);
    const mqtt_onMsgRx = (msg) => {
        try {
            console.log("rx msg from " + msg.destinationName + " with content " + msg.payloadString);


            let d = JSON.parse(msg.payloadString);
            // do stuff with it
            // find device in table, error if not one of ours
            // device id is the last component of the topic name
            let deviceId = msg.destinationName.split('/').slice(-1)[0];

            setStatusMessage(' subscribe for ' + deviceId);
            //let device = findDevice(deviceId);
            let device = findDeviceIds(deviceId);
            if (device === null) {
                console.log("rx msg from " + deviceId + " but can't find it");
                return;
            }


        } catch (e) {
            console.log(e);
        }
    }
    const findDeviceIds = (deviceId) => {
        // Assuming deviceIds is an array of valid device IDs
        if (deviceIds.includes(deviceId)) {
            return deviceId; // or return the device object if you have more info
        }
        return null;
    };

//##############################################################



//###############################################################

///              proces mqtt data                            ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  proces mqtt data//          *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/



//##############################################################



//###############################################################

///              mqtt send data                              ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  mqtt send data  //          *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/



    const mqtt_send_data = (client, deviceId, data) => {
        console.log("mqttdecive"+deviceId);

        try {


            let sd = JSON.stringify(data);
            console.log("MQTT Client:", client);
            console.log("MQTT data:", sd);

            if (client !== null) {
                client.publish("ife-int/device/msgs-down/" + deviceId, sd, {qos: 1});
                updateLog("sent " + sd + " to " + "ife-int/device/msgs-down/" + deviceId);
            } else {
                updateLog("no mqtt connection to send data to " + deviceId + " : " + sd);
            }
        } catch (e) {
            console.log(e);
            // Add any additional error handling as necessary
        }
    };



    const ies_add = (client, deviceid) => {
        let msg = {"app": {}};

        msg.app["dev"] = {
            "vibration": {"song": "V10"},
            "buzzer": {"song": "bb01"},
            "led": {"song": "99910"}
        };
        updateLog("added device" + deviceid);

        return mqtt_send_data(client, deviceid, msg);
    };

//##############################################################



//###############################################################

///              operation system                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  operation system     //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/



    const saveUserData = (u) => {
        const data = {devices, workers};
        localStorage.setItem('taskmgr.persist.' + u, JSON.stringify(data));
    };

//###############################################################
///                                 ///
//###############################################################

///              find device and worker                                     ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /***** ///  find device and worker //   *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const findDevice = (deviceId) => {
        // updateLog("device length: " + devices.length);
        let b = devices
        //updateLog("find device: Found - " + deviceId);


        for (var i = 0; i < devices.length; i++) {
            //updateLog("Comparing deviceId:"+ deviceId+ "with devices[i].deviceId:"+ devices[i].deviceId);

            if (devices[i].deviceId === deviceId) {
                //   updateLog("find device: Found - " + JSON.stringify(devices[i]));


                return devices[i];
            }
            //updateLog("find device: Found - " + JSON.stringify(devices[i]));

        }
        //updateLog("find device " + deviceId);
        return null;
    }

//##############################################################



//###############################################################

///              delete                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  delete     //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    const deleteDevices = () => {
        setDevices([]); // Clears the entire device list
        globalVisitorId = null;
        setIsDeviceAdded(false);
        setVisitorId='';
        setLocation = 'Security'
        saveUserData(currentUser); // Save the updated state after deletion.
        updateLog("All devices have been deleted.");


    };


//##############################################################



//###############################################################

///              add device                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  add device     //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const addDeviceEvent = (deviceid, eventype, eventdetail) => {
        const deviceIndex = devices.findIndex(device => device.deviceId === deviceid);
        if (deviceIndex === -1) {
            console.log("failed to insert event for unknown device " + deviceid);
            return;
        }
        const updatedDevices = devices.map((device, index) =>
            index === deviceIndex ? {
                ...device,
                events: [...device.events, {"at": niceDate(new Date()), "type": eventype, "detail": eventdetail}]
            } : device
        );
        setDevices(updatedDevices);
    };


    const addDevice = (deviceid, nom) => {
        updateLog("addDevice: Function called" + nom);

        if (devices.some(device => device.deviceId === deviceid)) {
            updateLog("Existing devices:", devices);
            popup("device already exists");
            updateLog("device already exists");
            return;
        }
        /*if (devices.some(device => device.nom === nom)) {
            popup("device name already exists");
            updateLog("device name already exists");
            return;
        }*/
        // ... MQTT subscription and other logic
        const client = mqttClient
        const subscriptionSuccessful = mqtt_subscribe(client, deviceid);
        if (!subscriptionSuccessful) {
            updateLog("Device " + nom + " not found... but adding anyway");
        }
        updateLog("Device " + nom + " not found... but adding anyway");

        // Create a new device object
        const newDevice = {
            deviceId: deviceid,
            nom: nom,
            currentLocation: "Security",
            allocatedTo: null,
            allocatedAt: null,
            lastSeenAt: "never",
            events: [],
            status: ""
        };

        // Add the new device to the state
        setDevices([...devices, newDevice]);
        //updateLog("new Device " + deviceid + "kkk");
        updateLog("find device: adddevice - " + JSON.stringify(devices));


        let device = devices.find(d => d.deviceId === deviceid);




        // Add an event for the new device
        addDeviceEvent(deviceid, "added", "");

        if (device === null) {
            updateLog("Received message from " + deviceId + " but can't find it");
            return;
        }
        else{
            updateLog("found")}

        // Save user data (assuming saveUserData is defined)
        saveUserData(currentUser);

    };



    const onAddOk = async () => {
        updateLog("onAddOk: Function called");

        let deviceId = newDeviceId;
        updateLog("onAddOk: Device ID after modification", deviceId);

        if (deviceId.length === 4) {
            deviceId = "240ac40a" + deviceId;
        }
        deviceId = deviceId.toLowerCase();

        if (deviceId.length !== 12) {
            updateLog("onAddOk: Device ID validation failed", deviceId);

            popup("Please enter your device ID as either exactly 4 or 12 hex digits");
            return;
        }
        ////////////////////new.v1//////////////////////
        const isDeviceOk = await checkDeviceState(deviceId);
        if (!isDeviceOk) {
            popup("Device is in DEP_DEALLOCING state, aborting add.");
            setShowAddDevicePopup(false);
            return; // Abort if device is not okay to add
        }
        ////////////////////new.v1//////////////////////
        const client = mqttClient



        updateLog("onAddOk: Validations passed, closing popup and adding device");
        addDevice(deviceId, newDeviceName);

        setIsDeviceAdded(true);
        setShowAddDevicePopup(false);
        //nextStep();
        //setNewDeviceId('');
        //  setNewDeviceName('');
// Ensure addDevice is adapted to work with React state
    };

    const onAddCancel = () => {
        setShowAddDevicePopup(false);
        setShowScanner(false);
        setNewDeviceId('');
    };





//##############################################################



//###############################################################


///              Return device                                  ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  Return device  //     *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const onReturn = (deviceid) => {
        updateLog("kk")
        const device = findDevice(deviceid); // Adapt findDevice to work with React state
        if (!device) {
            popup("device not found");
            return;
        }
        if (!device.allocatedTo) {
            popup("device not allocated!");
            return;
        }

        setReturnPopupData({
            deviceId: deviceid,
            deviceName: device.nom,
            workerName: device.allocatedTo,
            comments: ''
        });
           };

    async function onReturnOk(deviceId) {
        updateLog("Attempting to return the device");
        const token= "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTcwOTcxNDY1NCwianRpIjoiZWJjMWJhNDItYmY3YS00NzBiLWJmOTMtOTI4NzBkYmQyZTY1IiwibmJmIjoxNzA5NzE0NjU0LCJ0eXBlIjoiYWNjZXNzIiwic3ViIjoiQWJkdWxyYWhtYW5fZGVtby5WXzAifQ.uM6w3Uvydfak9me3FrD7Ze4gN8ZiQtZYnhBuevUEpvA";
        const url = `https://api.infrafon.cloud/ife/rest/v1/tenant/DemoEntity/device/${deviceId}`; // Adjust URL as necessary
        const requestOptions = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                "operator": {"$set": {"state": "DEP_DEALLOCING"}}
            })
        };

        try {
            const response = await fetch(url, requestOptions);
            if (response.ok) {
                const data = await response.json();

                updateLog(`Device return successful: ${JSON.stringify(data)}`);
                const client = mqttClient
                ies_add(client, deviceId);
                toast.success("Please return it from the device too!");

                setShowAddDevicePopup(false);
                // Save the updated state after deletion.
                updateLog("All devices have been deleted.");
                setTimeout(() => {
                    toast.info("You will be directed to the main page shortly.");

                    // Navigate to main page after an additional delay of 5 seconds
                    setTimeout(() => {
                        navigate('/');
                    }, 5000); // Delay of 5 seconds after the second notification
                }, 5000);

                // Additional logic after successfully updating the device state
            } else {
                // Handle HTTP errors
                toast.error(`Failed to return device, server responded with status: ${response.status}`);
                setShowAddDevicePopup(false);
            }
        } catch (error) {
            // Handle network errors
            toast.error(`Error while attempting to return device: ${error}`);
            setShowAddDevicePopup(false);
        }

    }



//##############################################################



//###############################################################

///              on About                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****       ///  on About   //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/




//##############################################################



//###############################################################

///              Additional functions                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  Additional functions //    *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
    const padZero = (n) => {
        if (n < 0) {
            return n;
        }
        if (n < 10) {
            return '0' + n;
        }
        return '' + n;
    };
    const niceDate = (d) => {
        try {
            // Need 0 pad to make fixed-size fields
            return (
                padZero(d.getHours()) +
                ':' +
                padZero(d.getMinutes()) +
                ':' +
                padZero(d.getSeconds()) +
                ' ' +
                padZero(d.getDate()) +
                '-' +
                padZero(d.getMonth() + 1) +
                '-' +
                d.getFullYear()
            );
        } catch (e) {
            return '"' + d + '"';
        }
    };


//##############################################################



//###############################################################

///              debug functions                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  debug functions //         *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/

    const updateLog = (message) => {
        setLogMessages(prevLogs => [...prevLogs, message]);
        console.log(message); // Also log to the console
    };
    const resetLog = () => {
        setLogMessages([]); // Clear the log messages
        updateLog("Log has been reset"); // Log the reset action
    };


    const logDevicesState = (deviceId) => {
        updateLog("find device: allocate - " + JSON.stringify(devices));
        updateLog("device id: " + devices.length);

        for (var i = 0; i < devices.length; i++) {
            updateLog("Comparing deviceId:" + deviceId + "with devices[i].deviceId:" + devices[i].deviceId);

            if (devices[i].deviceId === deviceId) {
                updateLog("find device: Found - " + JSON.stringify(devices[i]));


                return devices[i];
            }
        }
    };


//##############################################################



//###############################################################

///              JSX                                   ///
    /********************************************/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****  ///  JSX//                      *****/
    /*****                                  *****/
    /*****                                  *****/
    /*****                                  *****/
    /********************************************/
//#####################################################################
    return (

        <div className="device-management-container">
            <h1>Return-Device</h1>



            <div>
                {showTasksAndMessagesPopup && <div id="tasksAndMessagesPopup">Tasks and Messages Popup Content</div>}
                {showMessageModal && (
                    <div style={{position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)"}}>

                    </div>
                )} {showConfirmModal && <ConfirmModal message={modalMessage} onConfirm={handleConfirmClose}
                                                      onCancel={() => setShowConfirmModal(false)}/>}
                {/* ... other divs with conditional rendering */}
            </div>


            <div className="mqtt-data-display">


                {/* Rest of your component */}
                {!isDeviceAdded && (
                    <div className="fixed-add-button-container">
                        <button className="buttonAdd" onClick={() => setShowAddDevicePopup(true)}>Choose a Device</button>
                    </div>
                )}
                {isDeviceAdded && (

                    <div className="fixed-delete-button-container">
                        <button className="delete-button" onClick={deleteDevices}>Delete</button>

                    </div>
                )}
                {showAddDevicePopup && (
                    <div id="addDevicePopup">
                        <div id="devicePopup" className="input-with-icon">
                            <select
                                id="addDeviceID"
                                className="device-id-select"
                                value={newDeviceId}
                                onChange={(e) => setNewDeviceId(e.target.value)}
                            >
                                <option value="">Select a Device ID</option>
                                {deviceIds.map((deviceId, index) => (
                                    <option key={index} value={deviceId}>
                                        {deviceId.slice(-4)}
                                    </option>
                                ))}
                            </select>




                            <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                                <button className="okButton" style={{marginRight: "0.5rem"}} onClick={() => onReturnOk(newDeviceId)}>Return
                                    device
                                </button>
                                <button className="cancelButton" onClick={onAddCancel}>Cancel</button>

                            </div>
                        </div>

                    </div>
                )}



            </div>
            <ToastContainer />
        </div>

    );
}

export default NFCuiReturn;
